import React from "react";
const FooterBox = () => {
  return (
    <>
      <div class="container-fluid text-center">
      <footer class=" row text-center text-white bg-dark mt-5 align-self-end">
      
      

        <div class="text-center p-3">
          © 2020 Copyright:CloudSolution.com 
</div>
      </footer>
      </div>
    </>
  );
};

export default FooterBox;