import React from "react";

const Comman = () => {
  return (
    <>
      <section id="header">
        <div className="container ">
          <div className="row">
            <div className="slides-bg-img">
              <div className="overlay"></div>
              <div className="col-md-12  d-flex justify-content-center  flex-column">
                <div className="  content  ">
                  <h1>
                  Making your Digital <span> ideas happen!</span>

                  </h1>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has.
                  </p>

                  <button>Read More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};



export default Comman;