import React from 'react';


const Career =()=>{

  return  (
    <>
   <h1 className='mt-5 pt-5'>Hello Career Page</h1>
    </>
  )
}



export default Career;