import React from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
const ContectMap = () => {
  return (
    <>
      <div className="row">
        <div class="col-12 mb-4">
          <div className="map">
            <map>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d33847.186989898386!2d75.79245954728611!3d26.911516956381973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1693203002554!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
            
                referrerpolicy="no-referrer-when-downgrade"
                style={{ border: '0' , width:"100%" ,
                height: "50vh"}}
              ></iframe>
            </map>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContectMap;
