import React from "react";
import HomeBlog from "./Home-blog";
import HomeCard from "./Home-card";
import Contect from "./Contect";
import ContectMap from "./ContectMap";


const Home = () => {
  return (
    <>
      <section id="header">
        <div className="container ">
          <div className="row mt-5 pt-5">
            <div className="slides-bg-img">
              <div className="overlay"></div>
              <div className="col-md-12  d-flex justify-content-center  flex-column">
                <div className="  content  ">
                  <h1>
                    We Provide Best and Very Secure <span>Cloud Services</span>{" "}
                  </h1>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has.
                  </p>

                  <button>Read More</button>
                </div>
              </div>
            </div>
          </div>
          <HomeBlog />
          <br />
          <HomeCard />
          <br />
          <Contect />

          <ContectMap />
         
        </div>
      </section>
    </>
  );
};

export default Home;
