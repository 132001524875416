import React from "react";
import {
  BsFillBarChartLineFill,
  BsGlobeAsiaAustralia,
  BsPencilFill,
  BsAndroid2,
} from "react-icons/bs";
const HomeCard = () => {
  return (
    <>
      <div className="row  mt-5">
        <div className="col-12">
          <h1 className="heading text-decoration-underline">Service</h1>
          <p>
            We offered complete solution over public cloud like
            (Aws,Gcp,Azure,Allibaba)and having exprinced certified engineer for
            development and exprinced trainer for industrial training.
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6">
          <div class="card box border-0">
            <div class="card-body card-content">
              <div className="icons mr-2">
                <BsFillBarChartLineFill className="card-icon" />
              </div>
              <div className="text-content">
                <h5 class="card-title color">Server & Networking</h5>
                <p class="card-text">
                  We provide complete network and server support..
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card box border-0">
            <div class="card-body card-content">
              <div className="icons mr-2">
                <BsGlobeAsiaAustralia className="card-icon" />
              </div>
              <div className="text-content">
                <h5 class="card-title color">Website Development</h5>
                <p class="card-text">
                  Website development by using latest technology.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div class="col-md-6">
          <div class="card box border-0">
            <div class="card-body card-content">
              <div className="icons mr-2">
                <BsPencilFill className="card-icon" />
              </div>
              <div className="text-content">
                <h5 class="card-title color">Public Cloud Solution</h5>
                <p class="card-text">We provide Iaas /Paas Service</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card box  border-0">
            <div class="card-body card-content">
              
              <div className="icons mr-2 ">
                <BsAndroid2 className="card-icon" />
              </div>
              <div className="text-content">
                <h5 class="card-title color">App Development</h5>
                <p class="card-text">We built hybrid ap for anroid and ios.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeCard;
