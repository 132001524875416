import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from "./Home";
import About from "./About";
import Career from "./Career";
import Navbar from "./Navbar";
import Footer from "./Footer"
import { Routes, Route } from "react-router-dom";


const App = () => {

  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" Component={Home} />
        <Route exact path="/About" Component={About} />
        <Route exact path="/Career" Component={Career} />
        <Route to="/" />
      </Routes>
    <Footer/>
    </>
  );
};
export default App;
