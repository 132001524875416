import React from "react";
import Comman from "./comman";
const About = () => {
  return (
    <>
  <Comman/>
    </>
  );
};



export default About;