import React from "react";
import { NavLink } from "react-router-dom";
const Navbar = () => {
  return (
    <>
      <div className="container-fluid p-0">
        <nav className="navbar navbar-expand-lg navbar-light fixed-top  bg-nav ">
          <div className="container ">
            <NavLink className="navbar-brand" href="#">
            <img src="../image/logo.png" alt="" width={"100px"} />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse  justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto mb-2 mb-lg-0 fw-bold">
                <li className="nav-item">
                  <NavLink exact className="nav-link active"to={"/"}>
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/About"}>
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    to={"/Career"}
                  >
                  Career
                  </NavLink>
                </li>
              </ul>
           
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
export default Navbar;
