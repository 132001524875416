import React from "react";
import { BsFillGeoAltFill, BsPersonFill,BsEnvelopeFill } from "react-icons/bs";
const Contect = () => {
  return (
    <>
      <div className="row  mt-5">
        <div className="col-12">
          <h1 className="heading text-decoration-underline">Contect</h1>
        </div>
      </div>
      <div className="row mt-5 mb-5">
        <div className="col-md-4 shadow">
          <div className="content-address">
            <BsFillGeoAltFill className="icons"/>
            <h3>Address</h3>
            <p>Lorem,</p>
          </div>
        </div>
        <div className="col-md-4  shadow">
          <div className="content-address border-start border-end">
            <BsPersonFill className="icons"/>
            <h3>Phone</h3>
            <p>587xxxxxxx4</p>
          </div>
        </div>
        <div className="col-md-4 shadow ">
          <div className="content-address">
            <BsEnvelopeFill className="icons"/>
            <h3>Email</h3>
            <p>text@text.com</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contect;
