import React from "react";

const HomeBlog =()=>{
return(
    <>
  
    <div className="row">
            <div className="col-lg-6 pt-5 pt-lg-0 order-lg-1 ">
              <div className="image-col">
                <img
                  src="../image/cloud_computing.gif"
                  className="w-100"
                  alt="Home image"
                />
              </div>
            </div>
            <div className="col-lg-6 pt-5 pt-lg-0 order-lg-1 mt-2 content-text d-flex justify-content-center  flex-column">
              <h1>Complete web Solutions For Your Business</h1>
              <div className="pera">
                <span className="fst-italic mb-2">
                  {" "}
                  We are team of talanted software engineers and Designers
                </span>
                <p>
                  ✅ Cloudguy Techsolutions Llp is an industry leader in
                  professional,Dedicated Server, website design, development,
                  and hosting. We're truly a one-stop shop offering a full range
                  of solutions for anyone looking to grow business in the
                  digital world
                </p>
              </div>
            </div>
          </div>
          
    </>
)

}
export default HomeBlog